import ApiService from "@/common/api/api.service";

const ParecerDescritivoService = {
    async listarPorMAtriculaID(id, paginacao) {
        let { data } = await ApiService.get(
            `PainelProfessor/parecer-descritivo/listar-por-user-id-paginado?matriculaId=${id}&TamanhoDaPagina=${paginacao.TamanhoDaPagina}&NumeroDaPagina=${paginacao.NumeroDaPagina}`
        );
        return data;
    },
    async listarPorSegmentacaoEMatricula(segmentacaoId, matriculaId) {
        let { data } = await ApiService.get(
            `PainelProfessor/parecer-descritivo/listar?SegmentacaoId=${segmentacaoId}&MatriculaId=${matriculaId}`
        );
        return data;
    },
    async baixarPDF(id) {
        // TODO: voltar aqui quando a rota existir
        let { data } = await ApiService.get(`PainelProfessor/parecer-descritivo/imprimir?ParacerDescritivoId=${id}`);
        return data;
    },
};

export default ParecerDescritivoService;