<template>
    <modal
        tamanho="xl"
        :exibir="exibir"
        :titulo="$t('ACADEMICO.VISUALIZAR_PARECER')"
        @fechar="fechar"
    >
        <div v-if="parecer">
            <div v-html="parecer" />
            <hr />
        </div>
        <div v-else class="text-center my-4">
            <span class="text-danger">{{
                $t('ACADEMICO.VISUALIZAR_PARECER_TEXTO_AUSENTE')
            }}</span>
        </div>
        <b-card v-if="arquivoConvertido">
            <h5>{{ nomeArquivo }}</h5>
            <iframe
                :src="arquivoConvertido"
                frameborder="0"
                style="width: 100%; height: 500px"
            ></iframe>
        </b-card>
        <template #modal-footer>
            <div class="w-100 d-flex justify-content-end">
                <b-button variant="secondary" @click="fechar">
                    {{ $t('GERAL.FECHAR') }}
                </b-button>
            </div>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/modal/Modal.vue';
import pdf from '@/common/utils/pdf';

export default {
    components: {
        Modal,
    },
    props: {
        exibir: {
            type: Boolean,
            default: false,
        },
        dadosParecer: {
            type: Object,
            default: Object,
        },
    },
    data() {
        return {
            parecer: '',
            nomeArquivo: '',
            conteudoArquivo: '',
            arquivoConvertido: '',
        };
    },
    watch: {
        dadosParecer: {
            handler(novoParecer) {
                this.parecer = novoParecer.conteudoDocumento;
                this.nomeArquivo = novoParecer.nomeDocumento;
                if (novoParecer.chaveDocumento) {
                    this.converterArquivo(novoParecer.chaveDocumento);
                }
            },
            immediate: true,
        },
    },
    methods: {
        converterArquivo(chaveArquivo) {
            const indexDeRetirada = chaveArquivo.indexOf('base64,');

            const retirada = chaveArquivo.substr(0, indexDeRetirada + 7);

            const tipo = retirada.replace('data:', '').replace(';base64,', '');

            const retirado = chaveArquivo.replace(retirada, '');

            let bytes = pdf.base64ToArrayBuffer(retirado);

            var file = new Blob([bytes], { type: tipo });
            var fileURL = URL.createObjectURL(file);

            this.arquivoConvertido = `${fileURL}`;
        },
        
        fechar() {
            this.arquivoConvertido = null;
            this.$emit('fechar');
        },
    },
};
</script>
