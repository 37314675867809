<template>
    <div>
        <titulo-parecer-descritivo />
        <filtro-parecer-descritivo :filtro="filtro" @filtrar="getPareceres()" />
        
        <tabela-parecer-descritivo
            :items="items"
            @visualizarParecer="abrirModal"
        />
        <span v-if="items.length === 0" class="d-flex justify-content-center"
            >Nenhum parecer descritivo encontrado</span
        >
        <paginacao
            v-model="paginacao"
            :total="paginacao.totalDeElementos"
            @input="getPareceres"
        />
        <modal-exibir-parecer
            :exibir="exibirModal"
            :dados-parecer="this.parecer"
            @fechar="fecharModal"
        />
    </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// services
import ParecerDescritivoService from '@/common/services/parecer-descritivo/parecer-descritivo.service.js';

// Components:
import TituloParecerDescritivo from './components/TituloParecerDescritivo';
import FiltroParecerDescritivo from './components/FiltroParecerDescritivo';
import TabelaParecerDescritivo from './components/TabelaParecerDescritivo';
import Paginacao from '@/components/paginacao/Paginacao';
import ModalExibirParecer from './components/ModalExibirParecer';

export default {
    components: {
        TabelaParecerDescritivo,
        ModalExibirParecer,
        Paginacao,
        TituloParecerDescritivo,
        FiltroParecerDescritivo,
    },
    mounted() {
        this.getPareceres();
    },
    data() {
        return {
            filtro: {
                matriculaId: null,
            },
            items: [{}],
            paginacao: {
                numeroDaPagina: 1,
                totalDeElementos: 0,
                tamanhoDaPagina: 10,
            },
            exibirModal: false,
            parecer: {},
        };
    },
    methods: {
        getPareceres() {
            this.$store.dispatch(START_LOADING);
            ParecerDescritivoService.listarPorMAtriculaID(
                this.filtro.matriculaId,
                this.paginacao
            )
                .then(({ data }) => {
                    this.items = data.itens;
                    this.paginacao.numeroDaPagina =
                        data.paginacao.numeroDaPagina;
                    this.paginacao.totalDeElementos =
                        data.paginacao.totalDeElementos;
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => this.$store.dispatch(STOP_LOADING));
        },

        // funções modal,
        abrirModal(parecer) {
            this.parecer = parecer;
            this.exibirModal = true;
        },
        fecharModal() {
            this.parecer = {};
            this.exibirModal = false;
        },
    },
};
</script>
