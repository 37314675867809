<template>
  <b-modal
    :size="tamanho"
    :content-class="contentClass"
    :centered="centralizado"
    :hide-backdrop="ocultarFundo"
    :hide-footer="ocultarRodape"
    :hide-header="ocultarHeader"
    :id="id"
    :title="titulo"
    @hide="fecharModal"
  >
    <template #modal-header="{}">
      <slot name="modal-header"> </slot>
    </template>
    <slot> </slot>
    <template #modal-footer="{}">
      <slot name="modal-footer"> </slot>
    </template>
  </b-modal>
</template>

<script>
import mensagem from "@/common/utils/mensagem";
import helpers from "@/common/utils/helpers";

export default {
  name: "Modal",
  props: {
    exibir: {
      type: Boolean,
      default: false,
    },
    tamanho: {
      type: String,
      default: "md",
    },
    titulo: { type: String, default: null },
    contentClass: {
      type: [String, Array, Object],
      default: null,
    },
    campos: {type:Array,  default: Array },
    centralizado: { type: Boolean, default: false },
    ocultarRodape: { type: Boolean, default: false },
    ocultarFundo: { type: Boolean, default: false },
    ocultarHeader: { type: Boolean, default: false },
    validarPreenchimento: { type: Boolean, default: false },
    id: { type: String, default: "modal-componente" },
  },
  methods: {
    fecharModal(evento) {
      if (
        evento.trigger == "event" ||
        !helpers.validarAlteracoesEmCampos(this.campos) ||
        !this.validarPreenchimento
      ) {
        this.$emit("fechar");
        return;
      }
      evento.preventDefault();
      mensagem
        .confirmacao(null, this.$t("GERAL.CONFIRMACAO_SAIR"))
        .then((result) => {
          if (result.isConfirmed) {
            this.$emit("fechar");
          }
        });
    },
  },
  watch: {
    exibir(valor) {
      if (valor) this.$bvModal.show(this.id);
      else this.$bvModal.hide(this.id);
    },
  },
};
</script>
