<template>
    <topbar
        :titulo="$t('ACADEMICO.PARECER_DESCRITIVO')"
        :subtitulo="$t('ACADEMICO.PARECER_DESCRITIVO_DESCRICAO')"
        :breadcrumbs="breadcrumbs"
    />
</template>
<script>
// Utils & Aux:
import rotas from '@/common/utils/rotas';
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

// Components:

export default {
    components: { Topbar },
    data() {
        return {
            breadcrumbs: [
                { titulo: this.$t('ACADEMICO.ACADEMICO') },
                { titulo: this.$t('ACADEMICO.PARECER_DESCRITIVO') },
            ],
        };
    },
    methods: {
        // FUNÇÕES CRUD:
        cadastrar() {
            this.$emit('cadastrar', 'disciplina');
        },
        // FUNÇÕES AUXILIARES:
        irPara() {
            rotas.retornar();
        },
    },
};
</script>
