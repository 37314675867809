<template>
    <b-row v-if="opcoes?.alunosVinculados?.length > 0">
        <b-col md="4">
            <input-select-search
                ref="matriculaId"
                :options="opcoes.alunosVinculados"
                :label="$t('ACADEMICO.ALUNO')"
                v-model="filtro.matriculaId"
                required
            />
        </b-col>
    </b-row>
</template>

<script>
// helpers & utils
import JwtService from '@/common/utils/jwt';

// components
import { InputSelectSearch } from '@/components/inputs';
export default {
    name: 'FiltroParecerDescritivo',
    props: {
        filtro: { type: Object, default: Object },
    },
    components: {
        InputSelectSearch,
    },
    data() {
        return {
            opcoes: {
                alunosVinculados: [],
            },
        };
    },
    mounted() {
        this.getAlunosVinculadosResponsavel();
    },
    watch: {
        'opcoes.alunosVinculados': {
            handler(value) {
                if (value?.length > 0) {
                    this.filtro.matriculaId = null;
                    return;
                }
                this.filtro.matriculaId = JwtService.buscarMatricula();
            },
            immediate: true,
        },
        'filtro.matriculaId': {
            handler(value) {
                if (value) {
                    this.filtrar();
                }
            },
            deep: true,
        },
    },
    methods: {
        getAlunosVinculadosResponsavel() {
            this.opcoes.alunosVinculados =
                JwtService.buscarAlunosVinculados().reduce(
                    (alunosSemDuplicatas, aluno) => {
                        const existe = alunosSemDuplicatas.some(
                            (a) => a.value === aluno.matriculaId
                        );
                        if (!existe) {
                            alunosSemDuplicatas.push({
                                text: aluno.nome,
                                value: aluno.matriculaId,
                            });
                        }
                        return alunosSemDuplicatas;
                    },
                    []
                );
        },
        filtrar() {
            this.$emit('filtrar');
        },
    },
};
</script>
