<template>
    <b-table
        :fields="fields"
        :items="items"
        :empty-text="$t('GERAL.NENHUM_REGISTRO')"
        head-variant="light"
        stacked="md"
        show-empty
        striped
        hover
    >
        <template #cell(acoes)="item">
            <b-dropdown variant="outline-default">
                <b-dropdown-item @click="baixar(item.item.id)">
                    {{ $t('GERAL.BAIXAR') }}
                </b-dropdown-item>
                <b-dropdown-item @click="visualizar(item.item)">
                    {{ $t('GERAL.VISUALIZAR') }}
                </b-dropdown-item>
            </b-dropdown>
        </template>
        <modal />
    </b-table>
</template>

<script>
// utils e aux
import { START_LOADING, STOP_LOADING } from '@/store/Store';
// import { base64ToArrayBuffer } from "@/common/utils/pdf";
import mensagem from '@/common/utils/mensagem';

// services
import ParecerDescritivoService from '@/common/services/parecer-descritivo/parecer-descritivo.service.js';
import pdf from '@/common/utils/pdf';

// componentes

export default {
    props: {
        items: { type: Array, default: Array },
    },
    data() {
        return {
            fields: [
                {
                    key: 'anoLetivoDescricao',
                    label: this.$t('ACADEMICO.ANO'),
                    sortable: true,
                },
                {
                    key: 'turmaDescricao',
                    label: this.$t('ACADEMICO.PARECER_DESCRITIVO'),
                    sortable: true,
                },
                {
                    key: 'acoes',
                    label: this.$t('GERAL.ACOES'),
                },
            ],
        };
    },
    methods: {
        baixar(id) {
            this.$store.dispatch(START_LOADING);
            ParecerDescritivoService.baixarPDF(id)
                .then((result) => {
                    if (result.data.documentoResponse.documento) {
                        this.converterArquivoEBaixar(
                            result.data.documentoResponse.documento,
                            result.data.documentoResponse.nomeArquivo
                        );
                    }
                    if (result.data.arquivo) {
                        this.converterArquivoEBaixar(
                            result.data.arquivo,
                            'parecer_descritivo',
                            true
                        );
                    }
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },
        visualizar(item) {
            this.$emit('visualizarParecer', item);
        },
        converterArquivoEBaixar(
            chaveArquivo,
            nomeArquivo,
            isConverter = false
        ) {
            let bytes;
            let tipo = 'application/pdf';
            if (isConverter) {
                const indexDeRetirada = chaveArquivo.indexOf('base64,');
                const retirada = chaveArquivo.substr(0, indexDeRetirada + 7);
                tipo = retirada.replace('data:', '').replace(';base64,', '');
                const retirado = chaveArquivo.replace(retirada, '');
                bytes = pdf.base64ToArrayBuffer(retirado);
            } else {
                bytes = pdf.base64ToArrayBuffer(chaveArquivo);
            }
            var file = new Blob([bytes], { type: tipo });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(file);
            link.download = nomeArquivo;
            link.click();
        },
    },
};
</script>
